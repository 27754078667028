.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
}

.nav__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
  list-style: none;
}

.menu__link {
  font-weight: 400;
  font-size: 0.9rem;
  cursor: pointer;
}

.menu__link:hover {
  color: var(--link-active);
}

.logo h2 {
  color: var(--primary-color);
}

.light__mode {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.light__mode span {
  color: rgba(255, 255, 255, 0.637);
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.light-theme .light__mode span {
  color: #000;
  font-weight: 500;
}

.header__shrink {
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #0c123d;
}

.mobile__menu {
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
  display: none;
}

.light-theme .header__shrink {
  background: #f7f7f7;
  box-shadow: 2px 2px 10px -2px #ddd;
}

.light-theme .mobile__menu {
  color: #000;
}

/* ========== responsive ============== */
@media only screen and (max-width: 992px) {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(6, 11, 26, 0.868);
    z-index: 99999;
    display: none;
  }

  .menu {
    flex-direction: column;
    background: #0c123d;
    width: 250px;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999999;
  }

  .menu__active {
    display: block;
  }

  .mobile__menu {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 50px;
    line-height: 50px;
  }

  .mobile__menu {
    font-size: 1rem;
  }

  .logo h2 {
    font-size: 1.3rem;
  }
}
